import Vue from 'vue'
import Vuex from 'vuex'
import "firebase/compat/auth"
import "firebase/compat/app"
import firebase from '../main.js'
import router from '@/router'


Vue.use(Vuex)

export default new Vuex.Store({
  //***** データの格納 ******
  state: {
    // loginUser: null,
    user: {},
    status: false
  },
  //****** stateを更新する関数を登録 ******
  mutations: {
    onAuthStateChanged(state, user) {
      state.user = user; //firebaseが返したユーザー情報
    },
    onUserStatusChanged(state, status) {
      state.status = status; //ログインしてるかどうか true or false
    }
  },
  //****** stateのデータを加工して表示 ******
  getters:{
    user(state) {
      return state.user;
    },
    isSignedIn(state) {
      return state.status;
    }   
  },
  //****** mutationsをコミットする関数を登録 ******
  actions: {
    onAuthStateChanged({ commit },user){
      commit('onAuthStateChanged' , user)
    },
    onUserStatusChanged({ commit }){
      commit('onUserStatusChanged')
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          alert("ログアウトしましたよ＝");
          router.push("/");
        })
        .catch(error => {
          console.log(error);
        });
    },
    // ログイン
    login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          alert("ログイン成功!");
          this.$router.push("/");
        });
    }
  },
  modules: {
  }
})
