<template>
  <v-app>
    <v-sheet
        color="pink lighten-5"
      >
    <div class="py-5"></div>
      <v-container>
        <v-card 
          width="400px"
          class="mx-auto mt-4"
          cols= "12"
          sm="4"
        >
          <v-card-title>
              <h3 class="display-1">ログイン</h3>
          </v-card-title>
          <v-card-text>
            <p v-if="this.prevRoute.path=='/letter'">
              ※購入にはログインが必要です
            </p>
            <v-form>
              <v-text-field prepend-icon="mdi-account-circle" label="メールアドレス" v-model="email" />
              <v-text-field 
                v-bind:type="showPassword ? 'text' : 'password'" 
                prepend-icon="mdi-lock" 
                v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                label="パスワード" 
                @click:append="showPassword = !showPassword" 
                v-model="password"
              />
              <v-card-actions>
                <v-btn class="info" @click="login">ログイン</v-btn>
              </v-card-actions>
              <p>
                <router-link to="/password-remind"
                    >パスワードを忘れた方はこちら</router-link
                >
              </p>
              <p>
                <router-link to="/sign-up">新規ユーザ登録はこちら</router-link>
              </p>
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>
    </v-sheet>
  </v-app>
</template>

<script>
import firebase from '../main.js'
import "firebase/compat/auth"
// import { mapActions } from 'vuex'

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      showPassword : false,
      prevRoute: null
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
        vm.prevRoute = from
    })
  },
  methods: {
    login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          alert("ログイン成功!");
        console.log("リファラー：" + this.prevRoute.path);
          if(this.prevRoute.path=='/translate'){
            this.$router.push("/translate");
          }else if(this.prevRoute.path=='/letter'){
            this.$router.push("/stripe");
          }else{
            this.$router.push("/");
          }
        });
    }
    // ...mapActions(['login'])
  }
}
</script>
<style lang="scss" scoped>
.container{
  height: 100vh;
}

</style>