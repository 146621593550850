<template>
  <!-- データの取得・監視 -->
  <v-app>
    <v-sheet
       color="pink lighten-5"
    >
    <v-container>
      <v-row>
        <v-card
          class="mx-auto mt-4"
          width="400px"
          cols= "12"
          sm="4"
          v-for="letter in this.Letters" :key="letter.id"
        >
          <v-card-text
            class="text--primary"
          >
            {{ letter.letter }}
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="info"
              @click="sendLetter(letter.letter)"
            >
              これを選択
            </v-btn>
            <v-btn
              text
              color="pink lighten-1"
              @click=" getIndex(letter.id),deleteItem(delateId)"
            >
              削除
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
    </v-sheet>
  </v-app>
</template>

<script>
import firebase from '../main.js'
import "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
export default {
  letter: 'letterIndex',
  props:['letter'],
  data: function () {
    return {
      db: null,
      Letters: [],
      dialog: false,
      uid: '',
      // userID: firebase.auth().currentUser.uid
    }
  },
  created: function () {
    const _this = this;
    // _this.db = firebase.firestore();
    // _this.uid = firebase.auth().currentUser.uid;
    this.getUid();
   // Letters コレクションを監視する
    _this.db.collection('Letters')
    .where('userID','==',this.uid)
    .orderBy('letter','desc')
    .limit(10)
    .onSnapshot(function (querySnapshot) {
      _this.Letters = []
      querySnapshot.forEach(function (doc) {
        const data = doc.data()
        data.id = doc.id
        _this.Letters.push(data)
      })
    })
  },
  methods: {
    getUid: function(){
      this.db = firebase.firestore();
      this.uid = firebase.auth().currentUser.uid;
    },
    // 選択されたデータTranslateコンポーネントに送信
    sendLetter: function (letter) {
      // console.log(letter)
      this.$emit("getLetter", letter);
      this.TextArea= letter;
    },
    // 削除機能の実装
    // IDの取得
    getIndex :function(letter) {
      // console.log(letter);
      this.delateId = letter
    },
    // IDの削除
    deleteItem :function(deleteId) {
      this.db.collection("Letters").doc(deleteId).delete()
    }
  }
}
</script>
<style scoped>
.margin[data-v-404c8757] {
    margin-bottom: 20px;
}
.container{
  height: 130vh;
}
  
</style>