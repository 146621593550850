<template>
  <v-app>
    <v-sheet
       color="pink lighten-5"
    >
      <v-container>
        <v-card
          width="400px"
          class="mx-auto mt-5"
        >
          <v-card-title>
            <h3 class="display-1">ユーザ登録</h3>
          </v-card-title>
          <v-card-text>
            <v-form>
                <v-text-field 
                prepend-icon="mdi-account-circle" 
                label="ニックネーム" 
                v-model="nickname" 
              />
              <v-text-field 
                prepend-icon="mdi-account-circle" 
                label="メールアドレス" 
                v-model="email" 
              />
              <v-text-field 
                v-bind:type="showPassword ? 'text' : 'password'" 
                prepend-icon="mdi-lock" 
                v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                label="パスワード" 
                @click:append="showPassword = !showPassword"
                v-model="password"
              />
              <v-card-actions>
                <v-btn class="info" @click="signUp">これで登録する</v-btn>
              </v-card-actions>
              <p>
                <router-link to="/login">
                  すでにアカウントを持っている方はこちら
                </router-link>
              </p>
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>
    </v-sheet>
  </v-app>
</template>

<script>
import firebase from '../main.js'
import "firebase/compat/auth";
import "firebase/compat/firestore";

export default {
  name: 'Signup',
  data () {
    return {
      showPassword:false,
      nickname: '',
      email: '',
      password: '',
      db:null
    }
  },
  created: function(){
    this.db = firebase.firestore()
  },
  methods: {
    signUp: function () {
        if( this.nickname === '' || this.email === '' || this.password ===''){
            alert('入力してください')
            return
        }
        firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
        .then(user => {
          alert('ユーザ登録が完了しました！', user.email)
          this.addUser();
          this.$router.push("/");
    })
    .catch(error => {
        // alert(error.message)
        if(error.code === 'auth/email-already-in-use'){
            alert('メールアドレスはすでに利用されています')
        }else if (error.code === 'auth/missing-email') {
            alert('メールアドレスを入力してください')
        }else if (error.code === 'auth/invalid-email') {
            alert('メールアドレスの形式がおかしいです')
        } else if(error.code === 'auth/user-disabled') {
            alert('ユーザが無効になっています')
        } else if(error.code === 'auth/user-not-found') {
            alert('ユーザが存在しません')
        } else if(error.code === 'auth/wrong-password') {
            alert('パスワードが間違っています')
        } else if (error.code === 'auth/too-many-requests') {
            alert('パスワードを何度も間違えています')
        } else if (error.code === 'auth/weak-password') {
            alert('パスワードは6文字以上で入力してください')
        } else {
            alert(error.message)        
        }
    })
    },
    addUser: function(){
      const uid = firebase.auth().currentUser.uid;
      const time = firebase.firestore.FieldValue.serverTimestamp();
      this.db.collection('users').doc(this.email).set({
        userID: uid,
        nickname: this.nickname,
        email: this.email,
        time: time,
    })
    }
  }
}
</script>

<style lang="scss" scoped>
  .container{
    height: 100vh;
  }
  
  </style>