import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Letter from '../views/Letter.vue'
import Translate from '../views/Translate.vue'
import LetterTemplate from '../views/LetterTemplate.vue'
// import Column from '../views/Column.vue'
import Contact from '../views/Contact.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import SignUp from '../views/SignUp.vue'
import PasswordRemind from "../views/PasswordRemind.vue";
import Draft from '../views/Draft.vue'
// import Stripe from '../views/Stripe.vue'
import Wikipedia from '../views/Wikipedia.vue'
// import Blog from '../views/Blog.vue'
// import BlogPost from '../views/BlogPost.vue'
import Privacy from '../views/Privacy.vue'
import Team from '../views/Team.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/letter',
    name: 'Letter',
    component: Letter
  },
  {
    path: '/translate',
    name: 'Translate',
    component: Translate,
    // ログインしないと見れないページ
    meta: { requiresAuth: true }
  },
  {
    path: '/letter/:slug',
    name: 'LetterTemplate',
    component: LetterTemplate
  },
  // {
  //   path: '/column',
  //   name: 'Column',
  //   component: Column
  // },
  {
    path: '/contact/:number',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: "/password-remind",
    name: "PasswordRemind",
    component: PasswordRemind,
  },
  {
    path: '/draft',
    name: 'Draft',
    component: Draft
  },
  // {
  //   path: '/stripe',
  //   name: 'Stripe',
  //   component: Stripe
  // },
  {
    path: '/wikipedia',
    name: 'Wikipedia',
    component: Wikipedia
  },
  // {
  //   path: '/blog',
  //   name: 'Blog',
  //   component: Blog
  // },
  // {
  //   path: '/blog/:slug',
  //   name: 'BlogPost',
  //   component: BlogPost
  // },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/team',
    name: 'Team',
    component: Team
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 「ログインしないと見れないページ」にするための処理
// router.beforeEach(async (to, from, next) => {
//   const requiresAuth = to.matched.some(recode => recode.meta.requiresAuth);
//   if (requiresAuth && !(await firebase.getCurrentUser())) {
//     next({ path: "/login", query: { redirect: to.fullPath } });
//   } else {
//     next();
//   }
// });

export default router
