<template>
  <v-app>
    <v-sheet color="pink lighten-5">
      <div class="py-5"></div>
      <v-container>
        <h2 class="text-center">自動翻訳</h2>
        <v-textarea
          background-color="white"
          color="cyan"
          label="ここに翻訳したい文章を入力してください"
          v-model="TextArea"
        >
        </v-textarea>

        <v-row>
          <v-col>
            <!-- <v-btn
            class="info mx-auto"
            @click="addLetter"
            v-if="isLogin==true"
          >
            下書きに保存
          </v-btn>
          <v-btn
            class="mx-auto white--text"
            color="pink lighten-3"
            @click="changeShow"
            v-if="isLogin==true"
          >
            下書き一覧
          </v-btn>
          <p v-else>
            <router-link to="/login">ログイン</router-link>すれば下書き機能が使えます
          </p> -->
          </v-col>
        </v-row>
        <div class="py-2"></div>
        <v-container class="paper" id="paper">
          <v-container class="lines">
            <div class="text" contenteditable spellcheck="false">
              <p id="letter_body">{{ translate() }}</p>
            </div>
          </v-container>
        </v-container>
        <div v-show="isActive">
          <Draft @getLetter="emitEvent" />
        </div>
        <div id="goBack">
          <buttom @click="$router.go(-1)">
            <font-awesome-icon icon="fa-solid fa-arrow-left" />
          </buttom>
        </div>
      </v-container>
    </v-sheet>
  </v-app>
</template>

<script>
import firebase from "../main.js";
import "firebase/compat/firestore";
import Draft from "./Draft.vue";
let apiKey = "AIzaSyAi5Sr2SqTkmQJgiCw-8A4ASyGYleTBL4I";

export default {
  // name: 'TextArea',
  components: {
    Draft,
  },
  data() {
    return {
      isActive: false,
      db: null,
      TextArea: "",
      // 受け取ったデータを格納する初期値
      letter: "",
      isLogin: false,
      loginUser: null,
    };
  },
  created: function () {
    (this.db = firebase.firestore()),
      firebase.auth().onAuthStateChanged((user) => {
        // console.log("onAuthStateChanged", user);
        // ログイン状態の取得
        if (user) {
          this.isLogin = true;
          this.loginUser = user;
        } else {
          this.isLogin = false;
          this.loginUser = null;
        }
      });
  },
  methods: {
    // 翻訳
    translate: function () {
      let text = this.TextArea;
      let fromLang = "ja";
      let toLang = "ko";

      const URL =
        "https://translation.googleapis.com/language/translate/v2?key=" +
        apiKey +
        "&q=" +
        encodeURI(text) +
        "&source=" +
        fromLang +
        "&target=" +
        toLang;
      let xhr = new XMLHttpRequest();
      xhr.open("POST", [URL], false);
      xhr.send();
      if (xhr.status === 200) {
        const res = JSON.parse(xhr.responseText);
        const aaa = res["data"]["translations"][0]["translatedText"];
        return aaa;
      }
    },
    // 受け取ったデータを引数に指定し、親コンポーネントのletterに格納
    emitEvent(letter) {
      this.TextArea = letter;
      this.isActive = false;
    },
    // firestoreに保存
    addLetter: function () {
      const _this = this;
      const uid = firebase.auth().currentUser.uid;
      const time = firebase.firestore.FieldValue.serverTimestamp();

      if (this.TextArea === "") {
        alert("入力されていません。");
        return;
      }

      // Letters コレクションにドキュメントを追加
      this.db
        .collection("Letters")
        .add({
          letter: _this.TextArea,
          userID: uid,
          time: time,
        })
        .then(function () {
          // 追加に成功したら、TextArea を空にする
          _this.TextArea = "";
        })
        .catch(function () {
          // エラー時の処理
          // console.log(this.error)
        });
    },
    // 表示の切り替え
    changeShow() {
      this.isActive = !this.isActive;
    },
  },
  computed: {
    select() {
      return this.$actions.store.select;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap");
@import url("https://fonts.googleapis.com/css?family=Indie+Flower");
/* @import '("./variables.scss")'; */

h4 {
  margin-top: 30px;
}

// #app{
//   height:100%;
// }

.container {
  height: 130vh;
}

.v-btn:not(.v-btn--round).v-size--default {
  margin-right: 20px !important;
}

.paper {
  position: absolute;
  height: 80vh;
  width: 80%;
  background: rgba(255, 255, 255, 0.9);
  margin: 0 auto;
  left: 10%;
  /* top: 70%; */
  // margin-bottom: 30px;
  box-shadow: 0px 0px 5px 0px #888;
}

.lines {
  margin-top: 20px;
  height: calc(100% - 40px);
  width: 100%;
  background-image: repeating-linear-gradient(
    white 0px,
    white 24px,
    steelblue 25px
  );
}
.text {
  position: absolute;
  top: 85px;
  left: 30px;
  bottom: 10px;
  right: 30px;
  line-height: 25px;
  font-family: "Indie Flower";
  overflow: hidden;
  outline: none;
}

#goBack {
  width: 50px;
  height: 50px;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #3f98ef;
  opacity: 0.6;
  margin: 10px;
}

#goBack buttom {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  text-decoration: none;
}

#goBack buttom::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f060";
  font-size: 25px;
  color: #fff;
  position: absolute;
  width: 25px;
  height: 36px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
}
</style>
