<template>
  <v-app>
    <header>
      <v-card>
        <v-toolbar Fixed tabs color="pink lighten-4" flat>
          <v-app-bar-nav-icon
            @click="drawer = true"
            class="hidden-lg-only"
          ></v-app-bar-nav-icon>
          <v-toolbar-title @click="$router.push('/')">
            Korean-letter
          </v-toolbar-title>
          <v-tabs class="hidden-md-and-down">
            <v-tab router-link to="/" exact>Home</v-tab>
            <v-tab router-link to="/letter">テンプレート</v-tab>
            <v-tab router-link to="/translate">自動翻訳</v-tab>
            <v-tab router-link to="/contact/2">翻訳のお申し込み</v-tab>
          </v-tabs>
        </v-toolbar>
      </v-card>
      <router-view />
    </header>
    <!-- スマホ用 -->
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-title @click="$router.push('/')"
              >HOME
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/letter')">
              テンプレート
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/translate')">
              自動翻訳
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/contact/2')">
              翻訳のお申し込み
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- ここまでスマホ用 -->
  </v-app>
</template>

<script>
import firebase from "./main.js";
import "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { mapActions } from "vuex";

export default {
  name: "App",
  head: {
    link: [{ rel: "icon", href: "favicons/favicon.ico" }],
  },
  data() {
    return {
      isLogin: false,
      loginUser: null,
      drawer: false,
    };
  },
  created: function () {
    firebase.auth().onAuthStateChanged((user) => {
      // ログイン状態の取得
      if (user) {
        this.isLogin = true;
        this.loginUser = user;
        this.setLoginUser(user);
      } else {
        this.isLogin = false;
        this.loginUser = null;
      }
    });
  },
  methods: {
    nickname: function () {
      return firebase.auth().currentUser.email;
    },
    ...mapActions(["setLoginUser"]),
  },
};
</script>

<style lang="scss">
// ロゴの読み込み
@import "https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css";

// スマホの横ずれ防止
body {
  overflow-x: hidden;
}

.font {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
  font: bold;
}
.font:before {
  content: "＊*"; /*花に見せかけるためのアスタリスク*/
  color: #fff; /* アスタリスクの色 */
}
.v-toolbar__title {
  overflow: visible !important;
  margin-right: 50px !important;
}
.v-btn {
  .v-btn__content {
    // color :white;
    .v-icon {
      color: #424242 !important;
    }
  }
}

.container {
  h1 {
    margin-bottom: 30px;
  }
  h2 {
    // margin-bottom: 20px;
    // clear: both;
    padding: 10px 20px;
    font-size: 20px; /*文字サイズ*/
    text-align: center; /*文字をセンタリング*/
    letter-spacing: 0.1em; /*文字間隔を少しだけ広くする指定*/
    background: #ea96a3; /*背景色*/
    color: white; /*文字色*/
    border-radius: 100px; /*角を丸くする指定。大きめであれば適当で構いません。*/
  }
}

.v-application {
  .display-2 {
    color: white;
  }
  .font-weight-black {
    color: #424242;
  }
}

.v-application .font-weight-black[data-v-fae5bece] {
  text-shadow: 2px 3px 4px #ffc0cb;
}
</style>
