<template>
  <v-app>
    <v-sheet
      color="pink lighten-5"
    >
      <v-container>
        <v-card width="400px" class="mx-auto mt-5">
          <v-card-title>
            <h3 class="display-1">ログアウト</h3>
          </v-card-title>
          <v-card-text>
            <v-form>
              <p>ログアウトしますか？</p>
              <v-card-actions>
                <v-btn
                  class ="hidden-lg-only pink lighten-3 font-weight-bold white--text"
                  @click="logout"
                >
                    ログアウト
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>
    </v-sheet>
  </v-app>
</template>

<script>
// import firebase from '../main.js'
import "firebase/compat/auth";
import { mapActions } from 'vuex'

export default {
  name: "logout",
  methods: {
    // logout() {
    //   firebase
    //     .auth()
    //     .signOut()
    //     .then(() => {
    //       alert("ログアウトしました");
    //       this.$router.push("/");
    //     })
    //     .catch(error => {
    //       alert(error);
    //     });
    // }
    ...mapActions(['logout','deleteLoginUser'])
  }
};
</script>
<style lang="scss" scoped>
  .container{
    height: 100vh;
  }
  
  </style>