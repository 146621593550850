<template>
    <v-app class="pink lighten-5">
    <div class="py-5"></div>
        <v-container>
            <v-main>
                <h2 class="text-center">Wikipedia検索</h2>
                <div class="py-5"></div>
                <form @submit.prevent="submitSearch">
                    <v-text-field 
                        v-model="searchQuery"
                        placeholder="検索したいアイドル名を入力"
                        solo
                    >
                        <v-icon
                            @click="removeSearchQuery"
                            slot="append"
                            color="red"
                        >
                            mdi-close-circle
                        </v-icon>
                    </v-text-field>
                    <v-row class="justify-end">
                        <v-btn
                            class="ma-2"
                            color="pink lighten-3"
                            @click="submitSearch"
                        >
                        検索
                        <v-icon
                            dark
                            right
                        >
                            mdi-magnify
                        </v-icon>
                        </v-btn>
                    </v-row>
                        <div id="goBack">
                            <buttom @click="$router.go(-1)">
                                <font-awesome-icon icon="fa-solid fa-arrow-left" />
                            </buttom>
                        </div>
                        <!-- </div> -->
                </form>
            </v-main>
            <v-main
                v-show="isResult"
            >
                <v-row no-gutters>
                    <v-card
                        v-for="elem in wikiObj"
                        :key="elem"
                        width="400px"
                        class="mx-auto mt-4"
                        cols= "12"
                        sm="4"
                        color="white lighten-5"
                        v-bind:href="'http://ja.wikipedia.org/?curid='+elem.pageid"
                    >
                        <v-card-title v-text="elem.title">
                        </v-card-title>

                        <v-card-subtitle v-text="elem.extract">
                        </v-card-subtitle>  
                    </v-card>
                </v-row>
            </v-main>
        </v-container>
    </v-app>
</template>

<script>
import axios from 'axios'

export default {
  data(){
      return{
        wikiObj: [],
        isResult: false,
        searchQuery: '',
        jsonp:null
      }
  },
  filters:{
    htmlEscape:function(value) {
        return value.replace(/&amp;/g, '&');
    },
    dateTime:function(val) {
        return new Date(val).toGMTString().slice(0, -13);
    }
  },
  computed: {
    wikiURL:function () {
        const wikiURL = 'http://ja.wikipedia.org/?curid=' + this.elem.pageid;
        return wikiURL
    },
  },
  ready: function() {
  },
  methods: {
    removeSearchQuery: function() {
      this.searchQuery = '';
      this.isResult = false;
    //   location.reload();
    },
    submitSearch: function() {
    if(this.searchQuery == ''){
        alert('入力してね');
        return
    }        
        // 【memo】 origin=*で成功
     var reqURL = "https://ja.wikipedia.org/w/api.php?action=query&generator=search&prop=pageimages&gsrnamespace=0&origin=*&exsentences=1&exintro&explaintext&exlimit=max&prop=extracts&gsrlimit=10&gsrsearch="+this.searchQuery+"&format=json";
     console.log(reqURL)
    axios.get(reqURL)
    .then(response=>{
        this.wikiObj = response.data.query.pages;
        console.log(this.wikiObj);
        this.isResult = true;
        this.searchQuery = '';
    })
    .catch(err => {
        console.error(err);
      })
    }
  },
}
</script>
<style scoped>
@import url(https://use.fontawesome.com/releases/v5.6.4/css/all.css);

#goBack{
  width: 50px;
  height: 50px;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #3f98ef;
  opacity: 0.6;
  margin:10px;
}

#goBack buttom{
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  text-decoration: none;
}

#goBack buttom::before{
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f060';
  font-size: 25px;
  color: #fff;
  position: absolute;
  width: 25px;
  height: 36px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
}


</style>