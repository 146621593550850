import Vue from 'vue'
import VueHead from 'vue-head'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from "firebase/compat/app";
import "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/auth"
import "firebase/compat/storage"
import axios from "axios";
import VueAxios from "vue-axios";
// import VueStripeCheckout from 'vue-stripe-checkout'

// 以下に apiKey のコードを貼る
// var firebaseConfig = {
//   apiKey: "AIzaSyBcIvnwbm0v_tNkaei6MyEJHCa6JwiuOxs",
//   authDomain: "letter-app-f686a.firebaseapp.com",
//   projectId: "letter-app-f686a",
//   storageBucket: "letter-app-f686a.appspot.com",
//   messagingSenderId: "1030836702904",
//   appId: "1:1030836702904:web:0d090ef85d90256ad02e4d",
//   measurementId: "G-MKP9SBKDZC"
// };
// // バックエンドのfirebaseを初期化する
// firebase.initializeApp(firebaseConfig);


// ***** firebase ******
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyBcIvnwbm0v_tNkaei6MyEJHCa6JwiuOxs",
    authDomain: "letter-app-f686a.firebaseapp.com",
    projectId: "letter-app-f686a",
    storageBucket: "letter-app-f686a.appspot.com",
    messagingSenderId: "1030836702904",
    appId: "1:1030836702904:web:0d090ef85d90256ad02e4d",
    measurementId: "G-MKP9SBKDZC"
  });
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {});
}
// ***** ここまで ******

Vue.config.productionTip = false

Vue.use(Vuex);
Vue.use(VueHead);
Vue.use(VueAxios, axios);

export default firebase;

new Vue({
  vuetify,
  router,  // router を使う
  store,
  render: h => h(App)
}).$mount('#app')