var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('section',[_c('v-row',{staticClass:"back",attrs:{"no-gutters":""}},[_c('v-theme-provider',{attrs:{"dark":""}},[_c('v-container',[_c('v-row',{staticClass:"white--text",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-img',{attrs:{"src":_vm.image_src}})],1),_c('v-col',{staticClass:"white--text text-center",attrs:{"cols":"12","tag":"h1"}},[_c('span',{staticClass:"font-weight-black text-shadow-title",class:[
                  _vm.$vuetify.breakpoint.smAndDown ? 'display-2' : 'display-3' ]},[_vm._v(" Korean-letter ")])]),_vm._l((_vm.navs),function(ref,nav){
                var title = ref.title;
                var url = ref.url;
return _c('v-col',{key:nav,staticClass:"text-center",attrs:{"cols":"6","md":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_c('router-link',{attrs:{"to":url}},[_vm._v(" "+_vm._s(title)+" ")])],1)])})],2)],1)],1)],1)],1),_c('section',{staticClass:"grey lighten-3",attrs:{"id":"features"}},[_c('div',{staticClass:"py-12"}),_c('v-container',{staticClass:"text-center"},[_c('h2',{staticClass:"text-center"},[_vm._v("Korean-letterの特徴")]),_c('v-responsive',{staticClass:"mx-auto mb-12",attrs:{"width":"56"}},[_c('v-divider',{staticClass:"mb-1"}),_c('v-divider')],1),_c('v-row',_vm._l((_vm.features),function(ref,feature){
                var icon = ref.icon;
                var title = ref.title;
                var text = ref.text;
                var link = ref.link;
                var src = ref.src;
return _c('v-col',{key:feature,attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"py-12 px-4 radius",attrs:{"color":"grey lighten-5","flat":"","height":'570px'}},[_c('v-theme-provider',{attrs:{"dark":""}},[_c('v-container',[_c('v-avatar',{attrs:{"color":"pink lighten-4","size":"88"}},[_c('v-icon',{attrs:{"large":""},domProps:{"textContent":_vm._s(icon)}})],1)],1)],1),_c('v-card-title',{staticClass:"justify-center font-weight-black font",domProps:{"innerHTML":_vm._s(title)}}),_c('v-card-text',{staticClass:"subtitle-1",domProps:{"innerHTML":_vm._s(text)}}),_c('v-img',{staticClass:"img hidden-sm-and-down",attrs:{"src":src,"width":"150"}})],1)],1)}),1)],1),_c('div',{staticClass:"py-12"})],1),_c('v-sheet',{attrs:{"id":"contact","color":"pink lighten-4","dark":"","tag":"section","tile":""}},[_c('Contact')],1),_c('v-footer',{attrs:{"color":"pink lighten-3","padless":""}},[_c('v-row',{staticClass:"text-center",attrs:{"no-gutters":""}},[_c('v-col',_vm._l((_vm.links),function(ref,i){
                var title = ref.title;
                var url = ref.url;
return _c('v-btn',{key:i,staticClass:"my-2",attrs:{"to":url,"color":"white","text":""}},[_vm._v(" "+_vm._s(title)+" ")])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }