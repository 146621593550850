<template>
  <v-app>
    <v-sheet
       color="pink lighten-5"
    >
    <div class="py-5"></div>
      <v-container>
        <v-card width="350px" class="mx-auto mt-5">
          <v-card-title>
            <h3 class="display-1">パスワード再設定</h3>
          </v-card-title>
          <v-card-text width="80%">
            <p>
              入力したメールアドレスに<br />パスワード再設定用のURLをお送りします。
            </p>
            <v-form>
              <v-text-field
                prepend-icon="mdi-account-circle"
                label="sample@xxxx.com"
                v-model="email"
              />
              <v-card-actions>
                <v-btn
                 class="white--text"
                 color="blue"
                 @click="resetPassword"
                  >パスワードを変更する</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>
    </v-sheet>
  </v-app>
</template>

<script>
import firebase from '../main.js'
import "firebase/auth";
import "firebase/compat/firestore";

export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    resetPassword() {
      if (this.email == "") {
        alert("空です。入力してください");
        return;
      }
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(
          () => {
            alert(
              "ご登録のメールアドレスに再設定のリンクを送付いたしました。迷惑メールフォルダもご確認ください"
            );
            this.$router.replace("/");
          },
          (err) => {
            alert(
              "ご登録のアドレスがございません。再度メールアドレスのご確認をお願いします。"
            );
            this.email = "";
            console.log(err);
          }
        );
    },
  },
};
</script>
<style lang="scss" scoped>
  .container{
    height: 100vh;
  }
  
  </style>