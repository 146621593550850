<template>
  <v-app class="pink lighten-5">
  <div class="py-5"></div>
  <v-container>
    <h2 class="text-center">お手紙テンプレート</h2>
    <v-chip
      class="ma-2"
      color="info"
    >
      ファンレターの参考に！
    </v-chip>
    <div class="py-2"></div>
      <v-row no-gutters>
          <v-card
            v-for="(letter, i) in letteres"
            :key="i"
            width="400px"
            class="mx-auto mt-4"
            cols= "12"
            sm="4"
          >
            <router-link
              :to="'/letter/' + letter.fields.slug"
            >
              <v-img
                :src="letter.fields.image.fields.file.url"
                height="200px"
              ></v-img>
            </router-link>

            <v-card-title v-text="letter.fields.title">
            </v-card-title>

            <v-card-subtitle v-text="letter.fields.subText">
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                color="orange"
                text
                :to="'/letter/' + letter.fields.slug"
              >
                これを見てみる！
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="shows[i].show = !shows[i].show"
              >
                <v-icon>{{ shows[i].show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>
              <!-- ダイアログ -->
            <v-row justify="space-around">
              <v-col cols="auto">
                <v-dialog
                  v-model="shows[i].show"
                  transition="dialog-bottom-transition"
                  scrollable
                  width="600"
                >
                  <v-card width="600">
                    <v-toolbar
                      color="pink lighten-4"
                    >{{letter.fields.title}}
                    </v-toolbar>
                    <v-card-text>
                      <div class="py-2"></div>
                      <p>{{letter.fields.discription}}</p>
                    </v-card-text>
                    <v-card-actions class="justify-start">
                      <router-link
                        :to="'/letter/' + letter.fields.slug"
                      >
                        これを見てみる！
                      </router-link>
                    </v-card-actions>
                    <v-card-actions class="justify-end">
                      <v-btn
                        icon
                        @click="shows[i].show = false"
                        color="#3f98ef"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
    </v-container>
    <div class="py-5"></div>
  </v-app>
</template>

<script>
import firebase from '../main.js'
import "firebase/compat/firestore";
import {createClient } from "@/plugins/contentful";

export default {

  // el:'#app',
  data: () => ({
      shows: [],
      letteres:[],
      select: false,
    }),
  created: function() {
    this.db = firebase.firestore(),
    firebase.auth().onAuthStateChanged(user => {
        // ログイン状態の取得
        if (user) {
            this.isLogin = true;
            this.loginUser = user;
        }else{
            this.isLogin = false;
            this.loginUser = null;
        }
      });
  },
  async mounted() {
    await createClient()
      .getEntries({
        content_type: 'letter',
        order: '-sys.createdAt'
      })
      .then(res => {
        this.letteres = res.items;
        console.log(res.items);
        let number = this.letteres.length;
        console.log(number);
        console.log(this.letteres[0]);
        for(let i =0; i< number; i++){
          this.shows.push({
            show: false,
        });
      }
    });
  },
  methods:{
      },
  }
</script>
<style lang="scss" scoped>
</style>