<template>
  <v-app>
    <v-main class="pink lighten-5">
    <div class="py-5"></div>
      <v-container>
        <v-row>
           <!-- 関連リンク -->
          <!-- スマホの場合は非表示 -->
          <!-- <v-col cols="2" class="hidden-sm-and-down">
            <v-sheet rounded="lg">
               <v-list-item
                  link
                  class="pink lighten-4"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="font"
                    >
                      テンプレート
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              <v-list color="transparent">
                <v-list-item
                  v-for="(letter,i) in letteres"
                  :key="i"
                  :to="'/letter/' + letter.fields.slug"
                >
                  <v-list-item-content>
                      <v-list-item-title>
                        {{ letter.fields.title }}
                      </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-col> -->


          <v-col>
            <v-sheet
              min-height="70vh"
              rounded="lg"
            >
                <!-- 記事タイトル -->
                <h3 class="text-center">{{ letter.fields.title }}</h3>          
                <div class="py-3"></div>
                 <!-- タブ -->
                  <v-tabs
                    background-color="blue lighten-5"
                    centered
                  >
                    <v-tab class="primary--text">日本語</v-tab>
                    <v-tab class="primary--text">韓国語</v-tab>

                    <!-- 日本語 -->
                    <v-tab-item>
                      <v-card 
                        flat
                      >
                        <v-card-text>
                          <div class="paper">
                            <div class="lines">
                              <div id="paper-japanese" class="text" v-html="letter.fields.japanese">
                              </div>
                            </div>
                          </div>
                            <!-- ダウンロード -->
                            <v-row>
                              <v-btn
                                class="ma-2 hidden-sm-and-down mx-auto"
                                color="pink lighten-4"
                                id="save-btn"
                                @click="captureImageJapanese"
                              >
                                日本語をダウンロード
                                <!-- <v-icon
                                  dark
                                  right
                                >
                                  mdi-checkbox-marked-circle
                                </v-icon> -->
                              </v-btn>
                            </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>  

                    <!-- 韓国語 -->
                    <v-tab-item>
                      <v-card 
                      flat
                      >
                        <v-window>
                        <v-card-text>
                          <div class="paper">
                            <div class="lines">
                              <div id="paper-korea" class="text-korea" v-html="letter.fields.korean">
                              </div>
                            </div>
                          </div>
                          <!-- ダウンロード -->
                          <v-row>
                            <v-btn
                              class="ma-2 center hidden-sm-and-down mx-auto"
                              color="pink lighten-4"
                              centered
                              id="save-btn"
                              @click="captureImageKorea"
                            >
                              韓国語をダウンロード
                              <!-- <v-icon
                                dark
                                right
                              >
                                mdi-checkbox-marked-circle
                              </v-icon> -->
                            </v-btn>
                          </v-row>
                        </v-card-text>
                        </v-window>
                      </v-card>
                    </v-tab-item>
                  </v-tabs>

                <!-- <div class="blog-post"> -->
                <!-- 記事内容 -->
                <!-- <div class="center" v-html="letter.fields.content"></div>
                </div> -->

                <v-content class="pink lighten-5">
                  <div class="translate">
                    <h4>【簡易版】韓国語翻訳</h4>
                  <span class="small">~ 名前やグループ名の翻訳用にご利用ください！ ~</span>
                    <!-- input -->
                      <v-text-field v-model="Artist" label="(例)パク・ジミン">
                    </v-text-field>

                    <p class="font">韓国語はこれ？<span class="pink lighten-3" @click="select">{{translate()}}</span></p>
                </div>
              </v-content>
              <div id="goBack">
                <buttom @click="$router.go(-1)">
                    <font-awesome-icon icon="fa-solid fa-arrow-left" />
                </buttom>
              </div>
            </v-sheet>
          </v-col>
      </v-row>
    </v-container>
    </v-main>
  </v-app>
</template>

<script>
import html2canvas from 'html2canvas'
import {createClient } from "@/plugins/contentful";
let apiKey = 'AIzaSyAi5Sr2SqTkmQJgiCw-8A4ASyGYleTBL4I'

export default({
	data() {
		return {
        Artist:'',
        isActive: false,
        letter:[],
        letteres:[],
		}
	},
  methods: {
      // 表示非表示
      changeShow() {
        if(this.Artist===''){
          alert('入力してね')
        }else{
          this.isActive = !this.isActive          
        }
      },
      // 画像ダウンロード
      captureImageKorea () {
      html2canvas(document.querySelector('#paper-korea')).then((canvas) => {
        const link = document.createElement('a')
        link.href = canvas.toDataURL()
        link.download = `sample_letter_ko.png`
        link.click()
      })
    },
    captureImageJapanese () {
      html2canvas(document.querySelector('#paper-japanese')).then((canvas) => {
        const link = document.createElement('a')
        link.href = canvas.toDataURL()
        link.download = `sample_letter_ja.png`
        link.click()
      })
    },
    // 翻訳
    translate: function(){
        let text = this.Artist
        let fromLang = 'ja'
        let toLang = 'ko'

        const URL = "https://translation.googleapis.com/language/translate/v2?key="+apiKey+
        "&q="+encodeURI(text)+"&source="+fromLang+"&target="+toLang
        let xhr = new XMLHttpRequest()
        xhr.open('POST', [URL], false)
        xhr.send();
        if (xhr.status === 200) {
            const res = JSON.parse(xhr.responseText);
            const aaa = res["data"]["translations"][0]["translatedText"]
            return aaa
        }
    },
    // 翻訳を選択
    select: function(){
      this.Artist = this.translate()
      // コピー
      navigator.clipboard.writeText(this.Artist);
      alert( '「' + this.Artist + '」をコピーしました！');
      this.Artist = '';
    },
  },
  // テンプレートの取得取得
  async created() {
    await createClient()
        .getEntries({
          'content_type': 'letter',
          'fields.slug': this.$route.params.slug,
        })
        .then(res => {
          this.letter = res.items[0];
        });
  },
  // テンプレートの一覧取得
  async mounted() {
    await createClient()
    .getEntries({
      content_type: 'letter',
      order: '-sys.createdAt'
    })
    .then(res => {
      this.letteres = res.items;
      console.log(res.items)
    });
  },
  // pathの変更を検知してリロード
  watch:{
    $route(to) {
      this.current = to.params.page;
      location.reload();
    },
  }
})

</script>

<style scoped>
/* @import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&family=Nanum+Gothic&display=swap');

p{
  font-family: "sans-serif";
}

.font{
  font-family: 'M PLUS Rounded 1c', sans-serif !important;
}

.small{
  font-size:small;
}

h2.display-1 {
    text-align: center;
    margin-bottom: 30px;
    padding-top: 30px;
    font-family: 'M PLUS Rounded 1c', sans-serif;
}

h3{
  font-family: 'M PLUS Rounded 1c', sans-serif;
  padding-top: 10px;
}

h4{
    font-family: 'M PLUS Rounded 1c', sans-serif;
}

div .translate{
  padding: 30px;
}

#goBack{
  width: 50px;
  height: 50px;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #3f98ef;
  opacity: 0.6;
  margin:10px;
}

#goBack buttom{
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  text-decoration: none;
}

#goBack buttom::before{
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f060';
  font-size: 25px;
  color: #fff;
  position: absolute;
  width: 25px;
  height: 36px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
}

.img-center{
    margin:0 auto;
    margin-bottom: 30px;
}

div.center{
  text-align: center;
  margin: 30px 0px;
  padding: 30px;
}

buttom .center{
  margin: 0 auto;
}

body {
  margin: 0;
  padding: 0;
  background: lightgoldenrodyellow;
}
.paper {
  height: auto;
  width: 100%;
  background: rgba(255,255,255,0.9);
  margin: 0 auto;
  left: 50%;
  top: 50%;
  box-shadow: 0px 0px 5px 0px #888;
}
.lines {
  margin: 40px 0px 20px 0px ;
  height: calc(100% - 50px);
  width: 100%;
}
.text {
  line-height: 17px;
  overflow: hidden;
  outline: none;
  padding: 20px 20px 0px 20px;
  font-family: 'M PLUS Rounded 1c', sans-serif;
}

.text-korea {
  line-height: 17px;
  overflow: hidden;
  outline: none;
  padding: 20px 20px 0px 20px;
  font-family: 'Nanum Gothic', sans-serif;
}

.text h3{
  margin-bottom:16px;
}



</style>