<template>
  <v-app>
    <v-sheet id="contact" color="pink lighten-5" tag="section">
      <div class="py-3"></div>
      <v-container>
        <h2 class="text-center">翻訳のお申し込み</h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-theme-provider light>
          <form
            action="https://formcarry.com/s/Ug8jsL7mv8d"
            method="POST"
            accept-charset="UTF-8"
          >
            <v-col cols="12">
              <v-text-field
                flat
                required
                label="お名前*"
                solo
                type="text"
                name="氏名"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                flat
                required
                label="メールアドレス*"
                solo
                type="email"
                name="メールアドレス"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="selectValue"
                :items="items"
                label="件名*"
                required
                flat
                solo
                type="text"
                name="件名"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              v-if="this.selectValue === 'お手紙を翻訳してほしい'"
            >
              <v-select
                flat
                :items="sexs"
                label="性別"
                solo
                type="text"
                name="性別"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              v-if="this.selectValue == 'お手紙を翻訳してほしい'"
            >
              <span class="grey--text text--darken-3"
                >好きなアイドルのグループ名を教えてください！</span
              >
              <v-text-field
                flat
                label="(例) EXO"
                solo
                type="text"
                name="グループ名"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              v-if="this.selectValue == 'お手紙を翻訳してほしい'"
            >
              <span class="grey--text text--darken-3"
                >好きなアイドルのお名前を教えてください！</span
              >
              <v-text-field
                flat
                label="(例) カイ"
                solo
                type="text"
                name="アイドル名"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              v-if="this.selectValue == 'お手紙を翻訳してほしい'"
            >
              <span class="grey--text text--darken-3"
                >推しメンは年上ですか？年下ですか？</span
              >
              <v-select
                flat
                :items="old"
                label="(例)私の方が年上"
                solo
                type="text"
                name="年齢"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              v-if="this.selectValue == 'お手紙を翻訳してほしい'"
            >
              <v-radio-group v-model="radios" mandatory>
                <!-- 通常翻訳 -->
                <v-radio
                  label="通常翻訳を希望する"
                  value="通常翻訳を希望する"
                ></v-radio>
                <v-content>
                  <p>
                    日本語1文字につき<span class="blue--text font-weight-bold"
                      >3円</span
                    >で翻訳いたします。<br />
                    お支払い確認後、<span class="blue--text font-weight-bold"
                      >5日以内</span
                    >に翻訳した文章をメールにて送信いたします。<br />
                  </p>
                </v-content>
                <br />
                <!-- お急ぎ翻訳 -->
                <v-radio
                  label="お急ぎ翻訳を希望する"
                  value="お急ぎ翻訳を希望する"
                ></v-radio>
                <v-content>
                  <p>
                    日本語1文字につき<span class="blue--text font-weight-bold"
                      >5円</span
                    >で翻訳いたします。<br />
                    お支払い確認後、<span class="blue--text font-weight-bold"
                      >24時間以内</span
                    >に翻訳した文章をメールにて送信いたします。<br />
                  </p>
                </v-content>
                <br />
                <!-- 翻訳+カナ付き -->
                <v-radio
                  label="通常翻訳+カナ付きを希望する"
                  value="通常翻訳+カナ付きを希望する"
                ></v-radio>
                <p>
                  日本語1文字につき<span class="blue--text font-weight-bold"
                    >5円</span
                  >で翻訳いたします。<br />
                  お支払い確認後、<span class="blue--text font-weight-bold"
                    >5日以内</span
                  >に翻訳した文章をメールにて送信いたします。<br />
                </p>

                <br />
                <!-- お急ぎ翻訳+カナ付き -->
                <v-radio
                  label="お急ぎ翻訳+カナ付きを希望する"
                  value="お急ぎ翻訳+カナ付きを希望する"
                ></v-radio>
                <p>
                  日本語1文字につき<span class="blue--text font-weight-bold"
                    >6円</span
                  >で翻訳いたします。<br />
                  お支払い確認後、<span class="blue--text font-weight-bold"
                    >5日以内</span
                  >に翻訳した文章をメールにて送信いたします。<br />
                </p>
                <div class="py-2"></div>
                <v-content>
                  <p class="pink--text">
                    メールにてお支払い用のリンクを送信致しますので、そちらのリンクよりお支払いください。<br />
                    <span class="pink--text"
                      >※本お問い合わせと決済時のメールアドレスが一致するようにお願いします</span
                    >
                  </p>
                </v-content>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <v-textarea
                counter
                flat
                label="本文*"
                minlength="34"
                required
                solo
                type="text"
                name="入力内容"
                height="400px"
                v-model="myText"
              ></v-textarea>
            </v-col>
            {{ this.myrules.text }}
            <!-- 文字数 -->
            <input type="hidden" name="文字数" v-model="this.myText.length" />
            <!-- 1文字あたりの料金 -->
            <input
              type="hidden"
              name="1文字あたりの料金"
              :value="calculatePricePerChar(radios)"
            />
            <!-- 合計金額 -->
            <input
              type="hidden"
              name="合計金額"
              :value="calculateTotalPrice(myText.length, radios)"
            />
            <!-- 翻訳期限 -->
            <input
              type="hidden"
              name="期限(お支払い完了後いつまでに返信するか)"
              :value="calculateDeadline(radios)"
            />
            <input type="hidden" name="_gotcha" />
            <v-col class="mx-auto" cols="auto">
              <v-row>
                <v-btn
                  x-large
                  type="submit"
                  class="mx-auto pink lighten-3 font-weight-bold white--text"
                  color="white"
                >
                  送信
                </v-btn>
                <div class="py-12"></div>
              </v-row>
            </v-col>
          </form>
        </v-theme-provider>
      </v-container>
      <div class="py-12"></div>
    </v-sheet>
    <!-- </v-content> -->
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      items: [
        "お手紙を翻訳してほしい",
        "サイトの使い方を教えてほしい",
        "取材したい",
        "その他",
      ],
      sexs: ["女性", "男性", "どちらでもない"],
      old: ["私の方が年上", "私の方が年下"],
      selectValue: "",
      checkbox: false,
      myrules: [
        (text) =>
          text.length <= 500 || "最大文字数は800文字です。ご注意ください",
      ],
      radios: null,
      myText: "",
      title: "",
    };
  },
  created: function () {
    this.selectValue = "お手紙を翻訳してほしい";
  },
  methods: {
    calculatePricePerChar(radioValue) {
      switch (radioValue) {
        case "通常翻訳を希望する":
          return 3;
        case "お急ぎ翻訳を希望する":
          return 5;
        case "翻訳+カナ付きを希望する":
          return 5;
        case "お急ぎ翻訳+カナ付きを希望する":
          return 6;
        default:
          return 0; // 何も選択されていない場合
      }
    },
    calculateTotalPrice(length, radioValue) {
      // 文字数に1文字あたりの料金を掛けることで合計金額を計算
      const money = length * this.calculatePricePerChar(radioValue);
      return `${money}円`;
    },
    calculateDeadline(radioValue) {
      if (
        radioValue === "お急ぎ翻訳を希望する" ||
        radioValue === "お急ぎ翻訳+カナ付きを希望する"
      ) {
        return "24時間以内";
      } else {
        return "5日以内";
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c");

/* .v-application .font-weight-black[data-v-fae5bece]{
  text-shadow: 2px 3px 4px #ffc0cb;
} */
</style>
