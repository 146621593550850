<template>
  <v-app>
    <section>
      <v-row no-gutters class="back">
        <v-theme-provider dark>
          <v-container>
            <v-row class="white--text" justify="center">
              <v-col cols="4">
                <v-img :src="image_src"> </v-img>
              </v-col>
              <v-col class="white--text text-center" cols="12" tag="h1">
                <span
                  :class="[
                    $vuetify.breakpoint.smAndDown ? 'display-2' : 'display-3',
                  ]"
                  class="font-weight-black text-shadow-title"
                >
                  Korean-letter
                </span>
              </v-col>
              <v-col
                v-for="({ title, url }, nav) in navs"
                :key="nav"
                cols="6"
                md="6"
                class="text-center"
              >
                <p class="font-weight-bold">
                  <router-link :to="url">
                    {{ title }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-theme-provider>
      </v-row>
    </section>

    <section id="features" class="grey lighten-3">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="text-center">Korean-letterの特徴</h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="({ icon, title, text, link, src }, feature) in features"
            :key="feature"
            cols="12"
            md="6"
          >
            <v-card
              class="py-12 px-4 radius"
              color="grey lighten-5"
              flat
              :height="'570px'"
            >
              <v-theme-provider dark>
                <v-container>
                  <v-avatar color="pink lighten-4" size="88">
                    <v-icon large v-text="icon"></v-icon>
                  </v-avatar>
                </v-container>
              </v-theme-provider>

              <v-card-title
                class="justify-center font-weight-black font"
                v-html="title"
              ></v-card-title>

              <v-card-text class="subtitle-1" v-html="text"> </v-card-text>
              <v-img :src="src" class="img hidden-sm-and-down" width="150">
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>

    <v-sheet id="contact" color="pink lighten-4" dark tag="section" tile>
      <!-- お問い合わせ -->
      <Contact />
    </v-sheet>
    <v-footer color="pink lighten-3" padless>
      <v-row no-gutters class="text-center">
        <v-col>
          <v-btn
            v-for="({ title, url }, i) in links"
            :key="i"
            :to="url"
            color="white"
            text
            class="my-2"
          >
            {{ title }}
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
// import src from '@/assets/images/sample.jpg';
import Contact from "./Contact.vue";

export default {
  // name: 'App',
  components: {
    Contact,
  },
  head: {
    link: [{ rel: "icon", href: "favicons/favicon.ico" }],
  },
  data() {
    return {
      features: [
        {
          icon: "mdi-heart",
          title: "韓国在住経験者が真心込めて翻訳します！",
          text: "あなたのお手紙を人間味のある<br>韓国語に翻訳します。<br>お問い合わせからお申し込みください。",
          src: require("@/assets/herat_man_fuman.png"),
        },
        {
          icon: "mdi-chat-processing",
          title: "お急ぎ翻訳対応！",
          text: '明日までに翻訳された文章が欲しい！<br>という方にも安心のプランです。<br><span style="font-size:small;color:cornflowerblue;">※お支払い確認後、<br>24時間以内に翻訳した文章をお送りいたします</span>',
          src: require("@/assets/dance.png"),
        },
        {
          icon: "mdi-chat-outline",
          title: "カナ付き翻訳で会話サポート！",
          text: "日本語カナ付きの翻訳プランも準備いたしました。<br>推しの握手会、写真会で<br>あなたの想いを伝えましょう。<br>",
          src: require("@/assets/talk.png"),
        },
        {
          icon: "mdi-note-edit-outline",
          title: "手紙のテンプレートを参考に！",
          text: "韓国アイドル、俳優に送るファンレターの<br>テンプレートを掲載しています。<br>日本語と韓国語で切り替えができるため、<br>韓国語がわからない方にも安心です。",
          src: require("@/assets/finger_heart.png"),
        },
      ],
      icons: [
        {
          icon: "mdi-share-outline",
        },
      ],
      image_src: require("@/assets/logo_k.png"),
      links: [
        {
          title: "プライバシーポリシー",
          url: "/privacy",
        },
        {
          title: "利用規約",
          url: "/team",
        },
      ],
      navs: [
        {
          title: "お手紙を翻訳",
          url: "/contact/1",
        },
        {
          title: "自動翻訳",
          url: "/translate",
        },
        {
          title: "お手紙テンプレート",
          url: "/letter",
        },
        {
          title: "翻訳のお申し込み",
          url: "/contact/2",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["login"]),
  },
};
</script>

<style lang="scss" scoped>
.back {
  background: #ea96a3;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.v-application {
  a {
    text-align: center;
    color: #fff; /*文字色*/
    border-bottom: 2px solid transparent; /*下線の幅、線種、色。transparentは透明の事。*/
    text-decoration: none;
  }
  p {
    margin-bottom: 0px !important;
  }
}
.text-shadow-title {
  text-shadow: 3px 2px 5px cornflowerblue !important;
  font-family: "M PLUS Rounded 1c", sans-serif !important;
  margin-bottom: 30px;
  color: white;
}
.container {
  h2 {
    padding: 10px 20px;
    font-size: 20px; /*文字サイズ*/
    text-align: center; /*文字をセンタリング*/
    letter-spacing: 0.1em; /*文字間隔を少しだけ広くする指定*/
    background: #ea96a3; /*背景色*/
    color: #fff; /*文字色*/
    border-radius: 100px; /*角を丸くする指定。大きめであれば適当で構いません。*/
  }
  h3 {
    margin-bottom: 20px;
    clear: both;
    padding: 0 20px;
    font-size: 18px; /*文字サイズ*/
    text-align: center; /*文字をセンタリング*/
    letter-spacing: 0.1em; /*文字間隔を少しだけ広くする指定*/
    border: 2px solid #ea96a3; /*枠線の幅、線種、色*/
    border-radius: 100px; /*角を丸くする指定。大きめであれば適当で構いません。*/
    background: #fff; /*背景色*/
  }
}

span .small {
  font-size: small !important;
  color: cornflowerblue !important;
}

.img {
  margin: 0 auto;
}

/* 想像するやつ */
.balloon4 {
  position: relative;
  width: 56%;
  margin: 0 auto;
  margin-bottom: 5px;
  padding: 3px;
  background: #e3f2fd;
  border-radius: 30px;
}
.balloon4 p {
  margin: 0;
  padding: 0;
}

.radius {
  border-radius: 50%;
}
</style>
